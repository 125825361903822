import { render, staticRenderFns } from "./SelectStaffModal.vue?vue&type=template&id=a1998abc&scoped=true&"
import script from "./SelectStaffModal.vue?vue&type=script&lang=js&"
export * from "./SelectStaffModal.vue?vue&type=script&lang=js&"
import style0 from "./SelectStaffModal.vue?vue&type=style&index=0&id=a1998abc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a1998abc",
  null
  
)

export default component.exports