<!--
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-05-06 09:29:20
 * @LastEditors: hutian
 * @LastEditTime: 2021-08-10 11:53:01
-->
<template>
    <a-modal :title="handle == 'select_check' ? '员工列表' : '新增员工'" style="top: 8px;" width="70%" v-model="visible" :footer="null" :maskClosable="false">
      <div v-if="handle == 'select_add'">
        <a-table :loading="tableLoading" @change="changeTable" :customRow="changeTableRow" 
          :row-selection="rowSelection" :data-source="tableData" bordered rowKey="managerId" :pagination="false">
          <!-- <a-table-column title="职位" data-index="job" :ellipsis="true" align="center"></a-table-column> -->
          <a-table-column title="用户名" data-index="loginName" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="姓名" data-index="name" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="手机号" data-index="mobile" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="邮箱" data-index="email" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="所属门店" data-index="storeName" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="所属部门" data-index="deptName" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="所属角色" data-index="role" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="最后登录时间" data-index="lastLoginDate" :ellipsis="true" align="center"></a-table-column>
        </a-table>
      </div>
      <div v-if="handle == 'select_check'">
        <a-table :loading="tableLoading" @change="changeTable" :customRow="changeTableRow" 
         :data-source="tableList" bordered :rowKey="(record) => record.id" :pagination="false">
          <!-- <a-table-column title="职位" data-index="job" :ellipsis="true" align="center"></a-table-column> -->
          <a-table-column title="用户名" data-index="loginName" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="姓名" data-index="name" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="手机号" data-index="mobile" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="邮箱" data-index="email" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="所属门店" data-index="storeName" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="所属部门" data-index="deptName" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="所属角色" data-index="role" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="最后登录时间" data-index="lastLoginDate" :ellipsis="true" align="center"></a-table-column>
          <a-table-column title="操作" data-index="" :ellipsis="true" align="center" v-if="handle == 'select_check'" :width="200">
            <template slot-scope="text, row">
              <a @click="onDeleteStaff(row.managerId)">删除</a>
              <a-divider type="vertical" v-if="row.flagShopManager == false" />
              <a @click="onSetShopManager(row.managerId)" v-if="row.flagShopManager == false">设为店长</a>
            </template>
          </a-table-column>
        </a-table>
      </div>
      <div class="footer_btns" v-if="handle == 'select_add'">
        <a-button type="default" @click="onCancel">取消</a-button>
        <a-button type="primary" @click="toSubmit">提交</a-button>
      </div>
    </a-modal>
</template>

<script>

import { listDealer } from '../api/DealerStoreApi'

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
  data() {
    return {
      visible: false,
      handle: '',
      tableData: [],
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      tableLoading: false, //表格loading
      queryParam: {},
      selectedRowKeys: [],
      selectedRows: [],
      storeId: 0,
      tableList: []
    }
  },

  computed: {
    rowSelection() {
      const _this = this
      return {
        fixed: true,
        // type: 'radio', //"radio"
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          _this.selectedRowKeys = selectedRowKeys
          _this.selectedRows = selectedRows
        }
      }
    }
  },
  created() {

  },
  methods: {
    setRowData(id, name) {
      this.handle = name
      this.visible = true
      this.storeId = id
      if(name == 'select_add') {
        this.getList(0)
      }else {
        this.axios.get(`/api/base/store/dealerStore/selectManagerById/${id}`).then(res => {
          this.tableList = res.body
        })
      }
    },
    // 关闭弹框
    onCancel(){
      this.visible = false
    },

    // 获取员工数据
    getList(num) {
      if(num == 1) {
        this.queryParam = {}
      }
      let obj = {
        pageSize: this.page.pageSize,
        pageNumbet:this.page.current
      }
      this.queryParam.storeId = this.storeId
      const params = Object.assign({}, obj, this.queryParam)
      listDealer(params).then(res => {
        this.tableData = res.body.records
        this.page.total = res.body.total
      })
    },

    // 更改分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },
    getTableList(){

      if(this.handle == 'select_add') {
        this.getList(0)
      }else {
        this.axios.get(`/api/base/store/dealerStore/selectManagerById/${this.storeId}`).then(res => {
          this.tableList = res.body
        })
      }
    },

    // 设为店长
    onSetShopManager(id) {
      let obj = {
        storeId: this.storeId,
        managerId: id
      }
      this.axios.post(`/api/base/store/dealerStore/setUpStoreManager`, obj).then(res => {
        if(res.code == 200){
        this.$message.success(res.message)
        // this.visible = false
        this.getTableList()
        this.$emit('reload')
        }
      })
    },

    // 点击选中
    changeTableRow(record) {
      return {
        on: {
          click: e => {
            console.log(e)
            if (this.selectedRowKeys[0] === record.id) {
              this.selectedRowKeys = []
              this.selectedRows = []
            } else {
              this.selectedRowKeys = [record.id]
              this.selectedRows = [record]
            }
          }
        }
      }
    },
    // 添加选中员工
    toSubmit() {
      let arr = this.selectedRowKeys
      let obj = {
        storeId: this.storeId,
        managerIdList:arr
      }

      this.axios.post('/api/base/store/dealerStore/addManager', obj).then(res => {
        if(res.code == 200) {
          this.$message.success(res.message)
          this.visible = false
          this.$emit('reload')
        }
      })
    },

    // 删除员工
    onDeleteStaff(id) {
      this.$confirm({
        title: '确定要删除此员工？',
        onOk: () => {
          this.axios.get(`/api/base/store/dealerStore/delManagerByStore?storeId=${this.storeId}&managerId=${id}`).then(res => {
            if(res.code == 200) {
              // this.visible = false
              this.getTableList()
              this.$message.success(res.message)
              this.$emit('reload')
            }
          })
        }
      })
      
    }

  }
}
</script>

<style lang='scss' scoped>
.footer_btns {
  margin-top: 20px;
  text-align: right;
}
</style>