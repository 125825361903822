<template>
    <a-card :border="false">
        <div class="realtor">
            <!-- 查询表单 -->
            <a-form-model ref="searchForm" layout="horizontal" :label-col="{xs:8}" :wrapper-col="{xs:16}">
                <a-row :gutter="8">
                    <a-col :md="8" :xl="6" :xxl="6" :sm="12">
                        <a-form-model-item label="门店名称">
                            <a-input v-model="searchData.search" placeholder="门店名称/门店代码/联系人" allowClear></a-input>
                        </a-form-model-item>
                    </a-col>
                    <a-col :xxl="4" :xl="5" :md="8" sm="2">
                        <a-form-model-item :label-col="{span: 0}" :wrapper-col="{span:24}">
                            <a-button type="primary" @click="getDataFilter()" icon="search">查询</a-button>
                            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
                        </a-form-model-item>
                    </a-col>
                </a-row>
            </a-form-model>
            <div>
                <a-button type="primary" v-if="isAdd" @click="toHandler('add')">新增</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length > 0 && isEdit" type="primary" @click="toHandler('edit')">修改</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length > 0 && isSelectAdd" type="primary" @click="toHandler('select_add')">添加现有员工</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length > 0 && isStaffAdd" type="primary" @click="toHandler('add_staff')">新增员工</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length > 0 && isCheck" type="primary" @click="toHandler('select_check')">查看员工</a-button>
                <a-button v-if="selectedRowKeys && selectedRowKeys.length > 0 && isDelete" type="danger" @click="toHandler('del')">删除</a-button>
            </div>
            <!-- 数据表格 -->
            <a-table :loading="tableLoading" :row-selection="rowSelection" :rowKey="record => record.id"
                :columns="columns" :data-source="tableData" bordered :pagination="page"
                @change="changeTable" :customRow="changeTableRow">
                <span slot="imageUrl" slot-scope="item">
                    <ZoomMedia style="height: 20px" :value="item"></ZoomMedia>
                </span>
                <span slot="province" slot-scope="item, row">
                   {{row.province}}{{row.city}}{{row.area}}{{row.address}}
                </span>
              <span slot="staffNum" slot-scope="text, row" style="cursor: pointer; color: blue">
                <a @click="showStaff(row.id)">{{ text }}</a>
              </span>
            </a-table>
            <!-- 编辑表单 -->
            <DealerStore-edit-modal ref="DealerStoreEditModal" @reload="getData"></DealerStore-edit-modal>
            <SelectStaffModal ref="SelectStaffModal" @reload="reload"></SelectStaffModal>
            <StaffAddModal ref="StaffAddModal" @ok="getData"></StaffAddModal>
        </div>
    </a-card>
</template>

<script>
import { columns } from './components/colums.js'
import DealerStoreEditModal from './components/DealerStoreEditModal.vue'
import { delDealerStore, listDealerStore, selectByIdDealerStore } from './api/DealerStoreApi'
import SelectStaffModal from './components/SelectStaffModal'
import StaffAddModal from './components/StaffAddModal.vue'
import { checkPermission } from '@/utils/permissions'

const pageSource = {
    current: 1,
    pageSize: 10,
    total: 0
}
export default {
    name: 'dealerStore',
    components: {
        DealerStoreEditModal,
        SelectStaffModal,
        StaffAddModal
    },
    data() {
        return {
            columns: columns,
            tableData: [],
            searchData: {}, // 搜索条件
            scroll:{ y: 600 },
            tableLoading: false, //表格loading
            page: {
                current: 1,
                pageSize: 10,
                total: 0,
                showSizeChanger: true,
                showTotal: () => `共${this.page.total}条`,
            },
            selectedRowKeys: [],
            selectedRows: [],
            isAdd: checkPermission('dealer:store:add'),
            isEdit: checkPermission('dealer:store:edit'),
            isDelete: checkPermission('dealer:store:delete'),
            isCheck: checkPermission('dealer:store:check'),
            isSelectAdd: checkPermission('dealer:store:select_add'),
            isStaffAdd: checkPermission('dealer:store:staff_add'),
        }
    },
    computed: {
        rowSelection() {
            const _this = this
            return {
                fixed: true,
                type: 'radio', //"radio"
                selectedRowKeys: this.selectedRowKeys,
                onChange: (selectedRowKeys, selectedRows) => {
                    _this.selectedRowKeys = selectedRowKeys
                    _this.selectedRows = selectedRows
                }
            }
        }
    },
    methods:{
        reload(){
           this.getData()
        },
        showStaff(row){
          const _this = this
          _this.$refs.SelectStaffModal.setRowData(row, 'select_check')
        },
        /**
         * 获取表格数据
         */
        getData() {
            this.tableLoading = true
            listDealerStore ({
                pageNumber: this.page.current,
                pageSize: this.page.pageSize,
                ...this.searchData
            }).then(res => {
                if (res.code === 200) {
                    const { total, records } = res.body
                    this.page.total = total
                    this.tableData = records
                }
            }).finally(() => this.tableLoading = false)
        },
        /**
         * 筛选查询
         */
        getDataFilter() {
            // this.page = Object.assign({}, this.page)
            this.page.current = 1
            this.page.pageSize = 10
            this.getData()
        },
        /**
         * 重置
         */
        reset() {
            this.searchData = {}
            // this.page = Object.assign({}, this.page)
            this.page.current = 1
            this.page.pageSize = 10
            this.getData()
        },
        /**
         * 更改分页
         */
        changeTable(pagination) {
            this.page = pagination
            this.getData()
        },
        /**
         * 点击行选中
         */
        changeTableRow(record) {
            return {
                on: {
                    click: e => {
                        console.log(e)
                        if (this.selectedRowKeys[0] === record.id) {
                            this.selectedRowKeys = []
                            this.selectedRows = []
                        } else {
                            this.selectedRowKeys = [record.id]
                            this.selectedRows = [record]
                        }
                    }
                }
            }
        },
        toHandler(name) {
            const _this = this
            if (name === 'add') {
                _this.$refs.DealerStoreEditModal.setRowData({}, 'add')
                return
            }
            if (_this.selectedRows.length === 0) {
                _this.$notification.warning({
                    message: '请选择一条记录'
                })
                return
            }
            switch (name) {
                case 'edit':
                    _this.$refs.DealerStoreEditModal.setRowData(_this.selectedRows[0], 'edit')
                    break
                case 'del':
                    _this.$confirm({
                        title: '删除警告',
                        content: '确认要删除此条记录吗?',
                        okText: '确认',
                        cancelText: '取消',
                        async onOk() {
                            const res = await delDealerStore(_this.selectedRows[0].id)
                            if (res.code === 200) {
                                _this.$notification.success({ message: res.message })
                                _this.getData()
                            } else {
                                _this.$notification.error({ message: res.message })
                            }
                        },
                        onCancel() {}
                    })
                    break
                case 'select_add':
                    _this.$refs.SelectStaffModal.setRowData(_this.selectedRowKeys[0], 'select_add')
                    break
                case 'select_check':
                    _this.$refs.SelectStaffModal.setRowData(_this.selectedRowKeys[0], 'select_check')
                    break
                case 'add_staff':
                    _this.$refs.StaffAddModal.isShow(_this.selectedRows[0], 'add_staff')
                    break
            }
        },
    },


    created() {
        this.getData()
    }
}
</script>

<style lang="less" scoped>
    .ant-btn {
        margin-right: 8px;
        margin-bottom: 12px;
    }
</style>
